import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import ProfileVerifyConfirmationDialog from '@/modules/profile/components/dialog/ProfileVerifyConfirmationDialog.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-verify-button',
    props: ['isProfileReady'],
    components: {
        ProfileVerifyConfirmationDialog
    },
    data() {
        return {
            isVerifiable: false,
            isSubmitting: false,
            openDialog: false,
            UserVerificationStatusEnum
        };
    },
    watch: {
        isProfileReady: {
            immediate: true,
            deep: true,
            handler(value) {
                this.isVerifiable = value;
            }
        },
    },
    created() {
        this.isVerifiable = this.$props?.isProfileReady;
    },
    computed: {
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        ...mapGetters(USERS_STORE, ['user']),
        disabled() {
            return [UserVerificationStatusEnum.PENDING_VERIFICATION, UserVerificationStatusEnum.REJECTED, UserVerificationStatusEnum.VERIFIED]
                .includes(this.activeUserWorkstation?.verificationStatus);
        }
    },
    methods: {
        onToggleDialog() {
            this.openDialog = !this.openDialog;
        }
    }
});
