// import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { isImageAttachment } from '@/core/helpers/file.helper';
// import ComingSoon from '@/core/components/ui/ComingSoon.vue';
import DefaultEmptyState from '@/modules/profile/components/DefaultEmptyState.vue';
import ProfileBaseDialog from '@/modules/profile/components/dialog/ProfileBaseDialog.vue';
import ProfileBoxSlot from '@/modules/profile/components/ProfileBoxSlot.vue';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_PUBLIC_GALLERY_CACHE, } from '@/store/modules/attachment-cache/constants';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-gallery-slider',
    emits: ['on-save-gallery'],
    components: {
        ProfileBoxSlot,
        // ComingSoon,
        DefaultEmptyState,
        ProfileBaseDialog,
    },
    props: {
        isLoading: {
            type: Boolean
        },
        hasRequested: {
            type: String
        },
        gallery: {
            type: Array,
            required: true
        },
        galleryHasValue: {
            type: Boolean,
            default: false,
        },
        viewOnly: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    watch: {
        hasRequested: {
            immediate: true,
            handler(value) {
                if (value === this.type.toLowerCase()) {
                    this.hasFinishedRequest = !this.hasFinishedRequest;
                    this.loadGalleryAttachments();
                    this.onToggleDialog();
                }
            }
        },
    },
    data() {
        return {
            type: 'Gallery',
            modalTitle: 'Create gallery',
            openDialog: false,
            hasFinishedRequest: false,
            sliderData: [
                {
                    title: 'Solar panels'
                },
                {
                    title: 'Roof'
                },
                {
                    title: 'Air condition'
                },
            ],
            /* eslint-disable global-require */
            thumbnail: `${require('@/assets/images/gallery-image.png')}`,
            /* eslint-disable global-require */
            isAttachmentRequestComplete: false,
            galleryImages: []
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        previewImages() {
            return this.galleryImages.map((attachment) => attachment.src);
        },
    },
    created() {
        this.loadGalleryAttachments();
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, [
            'getAttachments',
            'resetCacheEntity'
        ]),
        onToggleDialog() {
            this.openDialog = !this.openDialog;
        },
        onSubmit(gallery) {
            this.$emit('on-save-gallery', gallery);
        },
        async loadGalleryAttachments() {
            const { user } = this;
            const { galleries } = user?.userPublicProfile || { galleries: [] };
            if (galleries) {
                this.resetCacheEntity(USER_PUBLIC_GALLERY_CACHE);
                await this.getAttachments({
                    name: USER_PUBLIC_GALLERY_CACHE,
                    attachments: galleries,
                })
                    .then((values) => {
                    const newValues = values.filter(Boolean);
                    if (newValues.length) {
                        this.galleryImages = newValues.filter((item) => {
                            return isImageAttachment(item.originalName);
                        });
                    }
                })
                    .catch(() => { })
                    .finally(() => { this.isAttachmentRequestComplete = true; });
            }
        },
    }
});
