const COMPLETE = 'complete';
const fieldsToShow = [
    'DocumentType',
    'FullName',
    // 'YearOfBirth',
    'Address1',
    // 'City',
    'PostalCode',
];
export default {
    mapDisplay(value) {
        const list = [];
        const identification = value.document ? JSON.parse(value.document) : {};
        if (identification && identification?.status === COMPLETE) {
            const { steps } = identification;
            const [data] = steps;
            for (const field of data.inputFields) {
                const name = field.FieldName;
                if (fieldsToShow.includes(name)) {
                    list.push({
                        name,
                        value: field.Value
                    });
                }
            }
        }
        return list;
    }
};
