import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { AUTH_TOKEN } from '@/core/constants';
import { getLocalStorageWithExpiry } from '@/core/utils/common';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_PUBLIC_PROFILE_CACHE } from '@/store/modules/attachment-cache/constants';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-photo',
    emits: ['on-update-profile'],
    props: ['profilePhoto', 'isLoading', 'viewOnly'],
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            profileImage: null,
            uploadingProfileImage: false,
            newUploadedImageKey: '',
            isProfileImageLoaded: false,
            isSubmitting: false,
            hasSavedProfilePhoto: false
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        }
    },
    watch: {
        profilePhoto: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.loadProfileImageUrl(value);
                }
            }
        }
    },
    async created() {
        const { isLoading, profilePhoto } = this.$props;
        this.uploadingProfileImage = isLoading;
        if (profilePhoto) {
            await this.loadProfileImageUrl(profilePhoto);
        }
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, [
            'getAttachments',
            'resetCacheEntity'
        ]),
        ...mapActions(USERS_STORE, [
            'getUserProfile',
            'saveUserProfile',
            'getAccountStatuses'
        ]),
        ...mapActions(USERS_STORE, [
            'getUser',
            'updateUser',
            'saveUserState'
        ]),
        loadProfileImageUrl(value) {
            this.uploadingProfileImage = true;
            this.getAttachments({
                name: USER_PUBLIC_PROFILE_CACHE,
                attachments: [{ attachment: value }],
                returnFirstOnly: true,
            })
                .then(async ({ src }) => {
                this.profileImage = src;
                this.uploadingProfileImage = false;
                this.newUploadedImageKey = value;
            })
                .catch(() => { })
                .finally(() => {
                this.uploadingProfileImage = false;
            });
        },
        handleErrorFiles(err) {
            this.$notify.error({
                title: 'Upload Error',
                message: err || 'Uploading files error.'
            });
        },
        onProgressUploadProfileImage() {
            this.uploadingProfileImage = true;
        },
        updateProfileImageOnSuccess(response) {
            this.profileImage = null;
            this.newUploadedImageKey = response.attachment;
            this.profileImage = response.src;
            this.uploadingProfileImage = false;
            if (this.newUploadedImageKey) {
                this.$emit('on-update-profile', {
                    disableSpinner: true,
                    profile: {
                        profileImage: this.newUploadedImageKey
                    }
                });
            }
        },
    }
});
