import { MoreFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
const __default__ = defineComponent({
    props: ['title', 'headerValue', 'cardHeight', 'cardMinHeight', 'galleryHasValue', 'viewOnly'],
    emits: ['on-toggle-dialog'],
    components: {
        MoreFilled
    },
    data() {
        return {
            hasValue: null
        };
    },
    watch: {
        headerValue: {
            immediate: true,
            deep: true,
            handler(value) {
                this.normalizeValue(value);
            }
        }
    },
    created() {
        this.normalizeValue(this.$props.headerValue);
    },
    methods: {
        onToggleDialog() {
            this.$emit('on-toggle-dialog');
        },
        normalizeValue(value) {
            if (value && typeof value === 'object' && !Object.keys(value).length) {
                this.hasValue = null;
            }
            else {
                this.hasValue = value;
            }
        }
    }
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "3533ed76": (_ctx.$props.cardHeight),
        "c3b6a92c": (_ctx.$props.cardMinHeight),
        "21419572": (_ctx.$props.width)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
