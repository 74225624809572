import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import ProfileVerifiedIcon from '@/modules/profile/components/ProfileVerifiedIcon.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-banner',
    data() {
        return {
            openIdentificationDialog: false
        };
    },
    components: {
        ProfileVerifiedIcon
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        isVerified() {
            return this.user?.userIdentification?.userVerificationStatus === UserVerificationStatusEnum.VERIFIED;
        },
        userProfileState() {
            return this.activeUserWorkstation?.verificationStatus;
        },
    },
    props: {
        banner: {
            type: String,
            required: false,
            default: ''
        },
        user: {
            type: Object,
        },
        showStatus: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        onToggleIdentificationDialog() {
            this.openIdentificationDialog = !this.openIdentificationDialog;
        }
    }
});
