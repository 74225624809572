import { defineComponent } from 'vue';
import DefaultEmptyState from '@/modules/profile/components/DefaultEmptyState.vue';
import ProfileBaseDialog from '@/modules/profile/components/dialog/ProfileBaseDialog.vue';
import ProfileBoxSlot from '@/modules/profile/components/ProfileBoxSlot.vue';
export default defineComponent({
    name: 'profile-expertise',
    emits: ['on-update-skills'],
    components: {
        ProfileBaseDialog,
        ProfileBoxSlot,
        DefaultEmptyState
    },
    props: {
        isLoading: {
            type: Boolean
        },
        hasRequested: {
            type: String
        },
        categories: {
            type: Array,
            required: true
        },
        viewOnly: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    watch: {
        hasRequested: {
            immediate: true,
            handler(value) {
                if (value === this.type.toLowerCase()) {
                    this.hasFinishedRequest = !this.hasFinishedRequest;
                    this.onToggleDialog();
                }
            }
        },
    },
    data() {
        return {
            type: 'Expertise',
            openDialog: false,
            hasFinishedRequest: false,
            /* eslint-disable global-require */
            thumbnail: `${require('@/assets/images/expertise-image.png')}`,
        };
    },
    methods: {
        onToggleDialog() {
            this.openDialog = !this.openDialog;
        },
        onSubmit(data) {
            this.$emit('on-update-skills', data);
        }
    }
});
