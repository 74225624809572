import { defineComponent } from 'vue';
import IdentificationService from '@/core/services/identification.service';
export default defineComponent({
    name: 'profile-identity-verified-dialog',
    emits: ['close'],
    props: ['userIdentification', 'openDialog', 'user'],
    data() {
        return {
            fieldValues: [],
            dialogFormVisible: false,
            dialogLabel: 'Verified Info'
        };
    },
    created() {
        this.dialogFormVisible = this.openDialog;
        if (this.userIdentification) {
            this.fieldValues = IdentificationService.mapDisplay(this.userIdentification);
        }
    },
    methods: {
        onDialogClose() {
            this.$emit('close');
        },
    }
});
