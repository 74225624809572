import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/core/components/ui/Loader.vue';
import $axios from '@/core/utils/axios-api-config';
import ProfileAccreditation from '@/modules/profile/components/ProfileAccreditation.vue';
import ProfileBanner from '@/modules/profile/components/ProfileBanner.vue';
import ProfileDescription from '@/modules/profile/components/ProfileDescription.vue';
import ProfileExpertise from '@/modules/profile/components/ProfileExpertise.vue';
import ProfileGallerySlider from '@/modules/profile/components/ProfileGallerySlider.vue';
import ProfileInsurance from '@/modules/profile/components/ProfileInsurance.vue';
import ProfileName from '@/modules/profile/components/ProfileName.vue';
import ProfilePhoto from '@/modules/profile/components/ProfilePhoto.vue';
import ProfileVerifyButton from '@/modules/profile/components/ProfileVerifyButton.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
import { CERTIFICATES_STORE } from '../../../store/modules/certificates';
import { INSURANCES_STORE } from '../../../store/modules/insurances';
export default defineComponent({
    name: 'profile-about',
    props: ['workstationType', 'data'],
    components: {
        ProfileAccreditation,
        ProfileDescription,
        ProfileBanner,
        ProfileExpertise,
        ProfileInsurance,
        ProfileGallerySlider,
        ProfilePhoto,
        ProfileName,
        Loader,
        ProfileVerifyButton
    },
    data() {
        return {
            isProfileReady: false,
            isSubmitting: false,
            hasRequested: '',
            profile: {
                description: '',
                profileImage: '',
            },
            categories: [],
            userInsurances: [],
            userCertificates: [],
            gallery: [],
            initializing: false,
            viewOnly: false
        };
    },
    watch: {
        accountStatuses: {
            immediate: true,
            deep: true,
            handler() {
                this.isProfileReady = this.isProfileVerifiable;
            }
        }
    },
    beforeUnmount() {
        this.initializing = true;
        this.setLoaderFalse();
        // reset vw
        window.localStorage.removeItem('vw');
    },
    beforeMount() {
        this.initializing = true;
        this.setLoaderFalse();
    },
    computed: {
        ...mapGetters(['isTradesperson']),
        ...mapGetters(USERS_STORE, ['user', 'accountStatuses', 'isProfileVerifiable', 'isHosted']),
        ...mapGetters(USER_WORKSTATION, ['userWorkstations', 'activeUserWorkstation']),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    },
    async created() {
        await this.onInitialize();
    },
    methods: {
        ...mapActions(['setPaymentSuccessfulModal']),
        ...mapActions(USERS_STORE, [
            // profile
            'getUserProfile',
            'saveUserProfile',
            // user
            'getAccountStatuses',
            'getUser',
            'updateUser',
            // skills
            'saveUserSkills',
            'getUserSkills',
            // insurances
            'addNewInsurance',
            'getUserInsurances',
            'updateUserInsurance',
            'removeUserInsurance',
            // certificates
            'addNewCertificate',
            'removeUserCertificates',
            'getUserCertificates',
            'updateBusinessDetails',
            'initializeUserState'
        ]),
        ...mapActions(INSURANCES_STORE, ['getInsurances']),
        ...mapActions(CERTIFICATES_STORE, ['getCertificates']),
        ...mapActions(USER_WORKSTATION, ['getUserWorkstation']),
        setLoaderFalse() {
            setTimeout(() => {
                this.initializing = false;
            }, 300);
        },
        resetDetails() {
            this.categories = [];
            this.userInsurances = [];
            this.userCertificates = [];
        },
        async onInitialize() {
            const { user, categories, authenticatedUser } = this;
            const { params } = this.$route;
            const userId = params.userId || user.id;
            // eslint-disable-next-line eqeqeq
            if (userId != authenticatedUser.userId) {
                window.localStorage.setItem('vw', `${userId}`);
                this.viewOnly = !this.viewOnly;
            }
            this.isSubmitting = true;
            this.resetDetails();
            // we disabled payment for profile
            // await this.checkSuccessfulWProfileTransaction();
            await this.getUser(userId).then((response) => {
                if (response.userPublicProfile) {
                    this.profile = { ...response.userPublicProfile };
                    this.setTempProfilePhoto();
                }
            }).catch(() => { });
            if (categories && !categories.length) {
                await this.setCategories(userId);
            }
            if (!this.userInsurances.length) {
                await this.setUserInsurances(userId);
            }
            if (!this.userCertificates.length) {
                await this.setUserCertificates(userId);
            }
            this.isSubmitting = false;
        },
        validateActiveSubscription() {
            const { user } = this;
            if (!user?.tpProfileSubscription) {
                this.$router.push({ name: 'dashboard' });
            }
        },
        async checkSuccessfulWProfileTransaction() {
            const { id: userId } = this.user;
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (params.session_id) {
                const url = `/payment/${userId}/session?sessionId=${params.session_id}&isTradespersonProfile=1`;
                await $axios.get(url)
                    .then(async (sessionResponse) => {
                    const { subscription } = sessionResponse;
                    if (subscription) {
                        this.setPaymentSuccessfulModal(true);
                    }
                });
                await this.initializeUserState();
            }
        },
        identifyType(data) {
            let type = '';
            if (typeof data === 'object') {
                const keys = Object.keys(data);
                if (keys.length) {
                    [type] = keys;
                }
            }
            return type;
        },
        async setUserCertificates(userId) {
            this.userCertificates = await this.getUserCertificates(userId).catch(() => { }) || [];
        },
        async setUserInsurances(userId) {
            this.userInsurances = await this.getUserInsurances(userId).catch(() => { }) || [];
        },
        async setCategories(userId) {
            const result = await this.getUserSkills(userId).catch(() => { }) || [];
            const ids = [];
            const list = [];
            for (const item of result) {
                const { subCategory } = item;
                const { category } = subCategory;
                const categoryId = category.id;
                const label = category.poLabel ? category.tpLabel : '';
                const iconName = category?.iconName;
                const subCategories = [];
                const subCategoryCopy = JSON.parse(JSON.stringify(subCategory));
                delete subCategoryCopy.category;
                if (!ids.includes(categoryId)) {
                    ids.push(categoryId);
                    subCategories.push(subCategoryCopy);
                    list.push({
                        value: categoryId,
                        label,
                        iconName,
                        subCategories
                    });
                }
                else {
                    const [foundItem] = list.filter((value) => value.value === categoryId);
                    foundItem.subCategories.push(subCategoryCopy);
                }
            }
            if (list.length) {
                this.categories = list;
            }
            console.log(this.categories, 'categorieesss');
        },
        setTempProfilePhoto() {
            if (!this.profile?.profileImage) {
                this.profile.profileImage = '...';
            }
        },
        async setFinishRequest(data) {
            await this.getAccountStatuses();
            await this.initializeUserState();
            this.isSubmitting = false;
            this.hasRequested = this.identifyType(data);
            this.setTempProfilePhoto();
            await this.getUserWorkstation({
                userId: this.user.id,
                userWorkstationId: this.activeUserWorkstation.id
            });
        },
        prepareRequest(data) {
            const value = data;
            this.hasRequested = '';
            if (!value?.disableSpinner) {
                this.isSubmitting = true;
            }
            delete value.disableSpinner;
            return value;
        },
        onUpdateProfile(data) {
            const value = this.prepareRequest(data);
            const profileData = {
                ...value[this.identifyType(data)],
                wsState: true
            };
            const payload = {
                userId: this.user.id,
                profile: profileData
            };
            this.saveUserProfile(payload)
                .then(async (response) => {
                this.profile = { ...response };
                this.$notify.success({
                    type: 'success',
                    message: 'Profile succesfully updated.'
                });
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Error updating profile.'
                });
            })
                .finally(() => {
                this.setFinishRequest(data);
            });
        },
        onUpdateSkills(data) {
            this.prepareRequest(data);
            this.saveUserSkills({
                userId: this.user.id,
                skills: data.expertise
            })
                .then(async () => {
                await this.setCategories(this.user.id);
                this.$notify.success({
                    message: 'Expertise successfully updated.'
                });
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Error updating expertise.'
                });
            })
                .finally(() => {
                this.setFinishRequest(data);
            });
        },
        onSaveInsurance(data) {
            this.prepareRequest(data);
            this.addNewInsurance(data.insurance)
                .then(async () => {
                this.$notify.success({
                    message: 'Insurance(s) successfully saved.'
                });
                await this.setUserInsurances(data.insurance.userId);
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Error submitting insurance details.'
                });
            })
                .finally(() => {
                this.setFinishRequest(data);
            });
        },
        onDeleteInsurance(data) {
            this.prepareRequest(data);
            this.removeUserInsurance(data.insurance)
                .then(async () => {
                this.$notify.success({
                    message: 'Insurance Successfully removed.'
                });
                await this.setUserInsurances(data.insurance.userId);
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Error removing insurance.'
                });
            })
                .finally(() => {
                this.setFinishRequest(data);
            });
        },
        onSaveCertificate(data) {
            this.prepareRequest(data);
            this.addNewCertificate(data.accreditation)
                .then(async () => {
                this.$notify.success({
                    message: 'New certificate successfully saved.'
                });
                await this.setUserCertificates(data.accreditation.userId);
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Error submitting certificate details.'
                });
            })
                .finally(() => {
                this.setFinishRequest(data);
            });
        },
        onDeleteCertificate(data) {
            this.prepareRequest(data);
            this.removeUserCertificates(data.accreditation)
                .then(async () => {
                this.$notify.success({
                    message: 'Accreditation Successfully removed.'
                });
                await this.setUserCertificates(data.accreditation.userId);
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Error removing accreditation.'
                });
            })
                .finally(() => {
                this.setFinishRequest(data);
            });
        },
        onUpdateBusinessDetail(data) {
            const value = this.prepareRequest(data);
            const { user } = this;
            const { userBusinessDetails } = user;
            const newData = { ...userBusinessDetails };
            delete newData.id;
            delete newData.dateCreated;
            delete newData.dateUpdated;
            const { address } = value;
            const payload = {
                id: user.userBusinessDetails.id,
                userId: user.id,
                formData: { ...newData, ...address }
            };
            this.updateBusinessDetails(payload)
                .then(async () => {
                ///
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Error updating address.'
                });
            })
                .finally(() => {
                this.setFinishRequest(data);
            });
        },
        onSaveGallery(data) {
            this.prepareRequest(data);
            const { user } = this;
            const { id: userId } = user;
            const { profile } = this;
            const { id, description, name, } = profile;
            const formData = {
                id,
                description,
                name,
                galleries: data.gallery
            };
            this.saveUserProfile({
                userId,
                profile: { ...formData, wsState: true }
            })
                .then(async (response) => {
                this.profile = { ...response };
                this.$notify.success({
                    type: 'success',
                    message: 'Profile succesfully updated.'
                });
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Error updating profile.'
                });
            })
                .finally(() => {
                this.setFinishRequest(data);
            });
        }
    }
});
