import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-verify-confirmation-dialog',
    emits: ['close'],
    props: ['openDialog'],
    data() {
        return {
            dialogFormVisible: false,
            dialogLabel: '',
            isSubmitting: false
        };
    },
    created() {
        this.dialogFormVisible = this.openDialog;
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
    },
    methods: {
        ...mapActions(USERS_STORE, ['activateUserAccount', 'initializeUserState']),
        ...mapActions(USER_WORKSTATION, ['updateUserWorkstationStatus']),
        onSubmit() {
            const { authenticatedUser } = this;
            const { userId } = authenticatedUser;
            this.isSubmitting = true;
            this.updateUserWorkstationStatus({
                userId,
                userWorkstationId: this.activeUserWorkstation.id,
                formData: {
                    verificationStatus: UserVerificationStatusEnum.PENDING_VERIFICATION
                }
            })
                .then(() => {
                this.$notify.success({
                    message: 'Successfully sent the request for verification'
                });
                this.initializeUserState();
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Error activating your account.'
                });
            })
                .finally(() => {
                this.isSubmitting = false;
                this.onDialogClose();
            });
        },
        onDialogClose() {
            this.$emit('close');
        },
    }
});
