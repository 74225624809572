import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import ProfileBaseDialog from '@/modules/profile/components/dialog/ProfileBaseDialog.vue';
import ProfileIdentityVerifiedDialog from '@/modules/profile/components/dialog/ProfileIdentityVerifiedDialog.vue';
// import ProfileIdentificationDialog from '@/modules/profile/components/dialog/ProfileIdentificationDialog.vue';
import ProfileDefaultCta from '@/modules/profile/components/ProfileDefaultCta.vue';
// import ProfileVerifiedIcon from '@/modules/profile/components/ProfileVerifiedIcon.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-name',
    emits: ['on-update-business-detail'],
    components: {
        // ProfileIdentificationDialog,
        ProfileIdentityVerifiedDialog,
        ProfileBaseDialog,
        // ProfileVerifiedIcon,
        // ArrowRightBold,
        ProfileDefaultCta
    },
    props: ['user', 'isLoading', 'hasRequested', 'profile', 'workstationType', 'activeUserWorkstationData', 'viewOnly'],
    watch: {
        hasRequested: {
            immediate: true,
            handler(value) {
                if (value === this.type.toLowerCase()) {
                    this.hasFinishedRequest = !this.hasFinishedRequest;
                    this.onToggleDialog();
                }
            }
        },
        windowWidth(newWidth) {
            this.windowWidth = newWidth;
        }
    },
    data() {
        return {
            customLabel: 'Business Address',
            type: 'Address',
            openDialog: false,
            hasFinishedRequest: false,
            openIdentificationDialog: false,
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        ...mapGetters(USERS_STORE, ['viewedUser']),
        fullName() {
            return `${this.activeUserWorkstationData?.workStationBusinessName}`;
        },
        userIdentification() {
            return this.user?.userIdentification;
        },
        userProfileState() {
            return this.activeUserWorkstation?.verificationStatus;
        },
        isVerified() {
            return this.user?.userIdentification?.userVerificationStatus === UserVerificationStatusEnum.VERIFIED;
        },
        showProfileVerificationIcon() {
            return [UserVerificationStatusEnum.VERIFIED, UserVerificationStatusEnum.PENDING_VERIFICATION, UserVerificationStatusEnum.NOT_ADDED]
                .includes(this.userProfileState);
        },
        address() {
            const { user, viewedUser } = this;
            const { userBusinessDetails } = viewedUser || user;
            let value = '';
            if (userBusinessDetails?.businessAddress) {
                value = userBusinessDetails?.businessAddress;
            }
            if (userBusinessDetails?.businessPostcode) {
                value = `${value} ${userBusinessDetails?.businessPostcode}`;
            }
            return value;
        },
        onlyPostCode() {
            const { user } = this;
            const { userBusinessDetails } = user;
            let value = '';
            if (userBusinessDetails?.businessPostcode) {
                value = `${userBusinessDetails?.businessPostcode}`;
            }
            return value;
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onToggleDialog() {
            this.openDialog = !this.openDialog;
        },
        onSubmit(data) {
            // normalize
            const { businessAddress, businessPostcode, preferredDistance } = data.address;
            const value = {
                address: {
                    businessAddress,
                    businessPostcode,
                    preferredDistance
                }
            };
            this.$emit('on-update-business-detail', value);
        },
        onToggleIdentificationDialog() {
            this.openIdentificationDialog = !this.openIdentificationDialog;
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        }
    }
});
