import { ArrowRightBold } from '@element-plus/icons';
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import CurrencyFilter from '@/filters/currency.filter';
import DefaultEmptyState from '@/modules/profile/components/DefaultEmptyState.vue';
import ProfileBaseDialog from '@/modules/profile/components/dialog/ProfileBaseDialog.vue';
// import ProfileActionLabel from '@/modules/profile/components/ProfileActionLabel.vue';
import ProfileBoxSlot from '@/modules/profile/components/ProfileBoxSlot.vue';
// import ProfileVerifiedIcon from '@/modules/profile/components/ProfileVerifiedIcon.vue';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-insurance',
    emits: ['on-save-insurance', 'on-delete-insurance'],
    components: {
        DefaultEmptyState,
        // ProfileActionLabel,
        ProfileBaseDialog,
        // ProfileVerifiedIcon,
        ProfileBoxSlot,
        ArrowRightBold
    },
    props: ['isLoading', 'hasRequested', 'userInsurances', 'viewOnly'],
    watch: {
        hasRequested: {
            immediate: true,
            handler(value) {
                if (value === this.type.toLowerCase()) {
                    this.hasFinishedRequest = !this.hasFinishedRequest;
                    // this.onToggleDialog();
                }
            }
        },
    },
    data() {
        return {
            type: 'Insurance',
            openDialog: false,
            hasFinishedRequest: false,
            UserVerificationStatusEnum,
            /* eslint-disable global-require */
            thumbnail: `${require('@/assets/images/insurance-image.png')}`,
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        insurances() {
            const { userInsurances } = this;
            let value = {};
            if (userInsurances && userInsurances.length) {
                value = userInsurances.map((item) => {
                    const { insurance, userVerificationStatus, metadata } = item;
                    return {
                        name: insurance.name,
                        sum: CurrencyFilter.formatToCurrency(metadata.sum),
                        expiryDate: moment(metadata.expiryDate).format('DD/MM/YYYY'),
                        isVerified: userVerificationStatus === UserVerificationStatusEnum.VERIFIED
                    };
                });
            }
            return value;
        }
    },
    methods: {
        onToggleDialog() {
            this.openDialog = !this.openDialog;
        },
        onSubmit(data) {
            if (this.validateAttachments(data)) {
                this.$emit('on-save-insurance', this.normalizeSaveRequest(data));
            }
        },
        onDelete(data) {
            this.$emit('on-delete-insurance', this.normalizeDeleteRequest(data));
        },
        validateAttachments(data) {
            const { insurance } = data;
            const { attachments } = insurance;
            const hasValue = attachments && attachments.length;
            if (!hasValue) {
                this.hasFinishedRequest = !this.hasFinishedRequest;
                this.$notify.error({
                    message: 'You need to upload your insurance file.'
                });
            }
            return hasValue;
        },
        normalizeDeleteRequest(request) {
            const { insurance: id } = request;
            const data = {
                insurance: {
                    userInsuranceId: id,
                    userId: this.user.id
                }
            };
            return data;
        },
        normalizeSaveRequest(request) {
            const { insurance } = request;
            const { type, expiryDate, sumCoverage, attachments } = insurance;
            const data = {
                insurance: {
                    insuranceId: type,
                    userId: this.user.id,
                    metadata: {
                        sum: sumCoverage,
                        expiryDate
                    },
                    attachments
                }
            };
            return data;
        }
    }
});
