import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import DefaultEmptyState from '@/modules/profile/components/DefaultEmptyState.vue';
import ProfileBaseDialog from '@/modules/profile/components/dialog/ProfileBaseDialog.vue';
// import ProfileActionLabel from '@/modules/profile/components/ProfileActionLabel.vue';
import ProfileBoxSlot from '@/modules/profile/components/ProfileBoxSlot.vue';
// import ProfileVerifiedIcon from '@/modules/profile/components/ProfileVerifiedIcon.vue';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-accreditation',
    emits: ['on-save-certificate', 'on-delete-certificate'],
    components: {
        ProfileBaseDialog,
        // ProfileVerifiedIcon,
        // ProfileActionLabel,
        ProfileBoxSlot,
        DefaultEmptyState,
        ArrowRightBold
    },
    props: ['isLoading', 'hasRequested', 'userCertificates', 'viewOnly'],
    watch: {
        hasRequested: {
            immediate: true,
            handler(value) {
                if (value === this.type.toLowerCase()) {
                    this.hasFinishedRequest = !this.hasFinishedRequest;
                }
            }
        },
    },
    data() {
        return {
            type: 'Accreditation',
            openDialog: false,
            hasFinishedRequest: false,
            UserVerificationStatusEnum,
            /* eslint-disable global-require */
            thumbnail: `${require('@/assets/images/accreditation-image.png')}`,
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
    },
    methods: {
        onToggleDialog() {
            this.openDialog = !this.openDialog;
        },
        onSubmit(data) {
            if (this.validateAttachments(data)) {
                this.$emit('on-save-certificate', this.normalizeSaveRequest(data));
            }
        },
        onDelete(data) {
            this.$emit('on-delete-certificate', this.normalizeDeleteRequest(data));
        },
        validateAttachments(data) {
            const { accreditation } = data;
            const { attachments } = accreditation;
            const hasValue = attachments && attachments.length;
            if (!hasValue) {
                this.hasFinishedRequest = !this.hasFinishedRequest;
                this.$notify.error({
                    message: 'You need to upload your accreditation file.'
                });
            }
            return hasValue;
        },
        normalizeDeleteRequest(request) {
            const { accreditation: id } = request;
            const data = {
                accreditation: {
                    userCertificateId: id,
                    userId: this.user.id
                }
            };
            return data;
        },
        normalizeSaveRequest(request) {
            const { accreditation } = request;
            const { type, membershipNumber, url, attachments } = accreditation;
            const data = {
                accreditation: {
                    certificateId: type,
                    userId: this.user.id,
                    metadata: {
                        registrationNumber: membershipNumber,
                        // postcode,
                        url
                    },
                    attachments
                }
            };
            return data;
        }
    }
});
