<template>
    <span class="cta">
        <span class="location-text"></span>
        <span class="default-link">{{description}}</span>
        <span class="icon"><el-icon :size="12" color="#fff"><ArrowRightBold/></el-icon></span>
    </span>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';

export default {
  components: {
    ArrowRightBold
  },
  props: ['description'],
};
</script>
<style lang="scss" scoped>
.cta {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    cursor: pointer;
}
.default-link {
  color: #ffa500;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: right;
  margin: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 23px;
    height: 23px;
    background-color: #ffa500;
}
</style>
